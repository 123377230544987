.ant-breadcrumb.research-breadcrumb {
  line-height: 20px;
  font-size: 12px;
  padding-bottom: 18px;
  color: rgba(102, 102, 102, 1) !important;
  > span > a {
    color: rgba(102, 102, 102, 1) !important;
  }
  > span:last-child {
    color: rgba(7, 97, 173, 1);
  }
}
