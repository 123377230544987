@import './variables.scss';

.chart-styling {
  padding-right: 18px;
  margin-top: 24px;
}
h3.title-style {
  font-size: 18px;
  font-weight: 600;
}
.ant-radio-button-checked {
  background: $primary-color !important;
}
.card-styling {
  padding-right: 18px;
}
div[name='saleOrderTypeIdDashboardChart'] .ant-select-arrow {
  margin-top: -10px !important;
}
.custom-filter-radio-btn {
  border: 0 !important;
  border-radius: 3px !important;
}
.custom-filter-radio-btn .ant-radio-button,
.ant-radio-button-checked {
  border-radius: 3px;
}
.custom-filter-radio-btn.ant-radio-button-wrapper::before {
  display: none !important;
}
.custom-filter-radio-group {
  border: 1px solid #edf0f4;
  padding: 3px !important;
  border-radius: 6px;
}
.space-process-bar {
  height: 40px;
  background: #f4f5f5;
  display: flex;
  align-items: center;
  border: 1px solid #f4f5f5;
  box-sizing: border-box;
  border-radius: 3px;
}
.space-process-bar-name {
  height: 36px;
  width: 35%;
  font-size: 14px;
  text-align: center;
  line-height: 36px;
  background-color: #fff;
  box-shadow: 0px 8px 8px rgba(102, 102, 102, 0.05);
  border-radius: 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 8px;
}
.space-process-bar-persen {
  height: 36px;
  width: 65%;
  position: relative;
}
.space-process-bar-persen-prev {
  position: absolute;
  left: 0;
  height: 100%;
  background-color: $primary-color;
  width: 35%;
  border-radius: 3px;
  font-size: 14px;
}
.space-process-bar-persen-prev span {
  margin-left: 5px;
  line-height: 36px;
}
.date-select-filter {
  height: 16px;
  width: 16px;
  background: #edf0f4;
  border-radius: 3px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0px 16px;
  cursor: pointer;
}
.date-select-head-text {
  color: #222222;
  font-weight: 600;
}
.date-select-body-text {
  color: #222222;
  font-weight: 400;
}
.text-center {
  text-align: center;
}
.items-center {
  align-items: center;
}
.view-full {
  color: $primary-color;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
}
.text-overflow-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1200px) {
  .mt-20-res {
    margin-top: 20px;
  }
}
