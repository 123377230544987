
.title-import-item-ticket-item {
  width: 100%;
  justify-content: space-between;
  height: 56px;
}
#import-item-ticket-create-button {
  position: absolute;
  right: 24px;
}

.import-item-ticket-table {
  width: 100%;
  table {
    tbody {
      tr {
        td {
          vertical-align: baseline;
        }
      }
    }
  }
}

