.title-import-item-ticket-item {
  width: 100%;
  justify-content: space-between;
  height: 56px;
}
#import-item-ticket-create-button {
  position: absolute;
  right: 24px;
}

.inventory-check-table-container {
  max-height: 600px;
  .import-item-ticket-table {
    max-height: 600px;
    overflow-y: auto;
    table {
      width: 100%;
      overflow-y: auto;
      tbody {
        tr {
          td {
            vertical-align: baseline;
          }
        }
      }
    }
  }
}
