.notification-list {
  height: 50vh;
  overflow-y: auto;
  .ant-list-item.noti-read {
    padding: 15px;
  }

  .ant-list-item.noti-unread {
    background-color: rgba($color: grey, $alpha: 0.15);
    padding: 15px;
    div .colored-circle {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: -2px;
      border-radius: 50%;
      border-style: solid;
      border-width: 0.5px;
      border-color: lightblue;
      height: 10px;
      width: 10px;
      background-color: lightblue;
    }
  }
  .red {
    color: red;
  }
  .prefix {
    font-weight: bold;
  }
  // .ant-list-item.ship-order {
  //   div .colored-circle {
  //     display: inline-block;
  //     margin-left: 5px;
  //     margin-right: 5px;
  //     margin-bottom: -2px;
  //     border-radius: 50%;
  //     border-style: solid;
  //     border-width: 0.5px;
  //     border-color: red;
  //     height: 10px;
  //     width: 10px;
  //     background-color: red;
  //   }
  // }

  .ant-list-item-meta-avatar {
    min-width: 1vw;
  }
}
