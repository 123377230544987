.login-title {
  font-weight: bold;
}
.login-card .ant-card-body {
  background-color: white !important;
}
.login-label {
  color: #666666;
}
.error-span {
  color: red;
  font-size: small;
}

.right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.login-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.otp-input {
  min-width: 550px;
  height: 42px;
  text-align: center;
  letter-spacing: 12px;
  font-size: 20px;
  font-weight: bold;
}
.ant-btn.btn-login[disabled] {
  background: #c8e1f2;
}

.forgot-password-label {
  font-size: 18px;
  margin: 15px auto;
}

.cool-down {
  color: grey;
}
