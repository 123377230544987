.body-content .ant-card-body {
  background-color: #ffffff;
}
.button-group {
  display: flex;
  height: 40px;
  margin-bottom: 0.8rem;
}

.react-ant-button.btn-bell {
  height: 40px;
  width: 42px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
}
.btn-filter {
  position: relative;
  height: 40px;
  width: 40px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  z-index: 0;
}

@media (max-width: 576px) {
  .btn-filter {
    display: none !important;
  }
}
.react-ant-button.create-button {
  margin-left: 8px;
  margin-right: 8px;
}
.setting-group-icon {
  display: flex;
  flex-wrap: row;
  button {
    border: none;
  }
}
.top-search {
  width: 80%;
  display: flex;
  align-items: center;
}
.search-bar, .ant-input, .ant-input-password {
  box-shadow: none;
  background-color: white !important;
  > span {
    color: rgba(51, 51, 51, 0.3) !important;
  }
  > #custome-search {
    background-color: #e7eaee !important;
  }
}

.ant-popover-arrow {
  display: none !important;
}
.popover-title-notify {
  display: flex;
  justify-content: space-between;
}
.noti-label {
  font-weight: bold;
}

.noti-popover {
  width: 20vw;
  min-width: 240px;
  .ant-list-item {
    padding-left: 15px;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .ant-popover-title {
    border-bottom: none !important;
  }
}

.noti-popover-setting {
  width: 15vw;
  min-width: 240px;
  .ant-list-item {
    padding-left: 15px;
  }
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .ant-popover-title {
    border-bottom: none !important;
  }
}

.icon-ellipsis {
  font-size: 24px !important;
  font-weight: bold !important;
}
.noti-label-div {
  display: flex;
  align-items: center;
}
.button-ellipsis {
  background: #ffffff !important;
  border: none !important;
  box-shadow: none !important;
}
.more-menu {
  min-width: 140px !important;
}
