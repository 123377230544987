@import './variables.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  color: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notification-container--bottom-center,
.notification-container--bottom-full,
.notification-container--bottom-left,
.notification-container--bottom-right,
.notification-container--center,
.notification-container--top-center,
.notification-container--top-full,
.notification-container--top-left,
.notification-container--top-right {
  min-width: 325px;
  position: absolute;
  pointer-events: all;
}
.notification-container--bottom-center,
.notification-container--center,
.notification-container--top-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: calc(50% - 175px);
  max-width: 350px;
}
.notification-container--center {
  top: 20px;
  height: 100%;
  pointer-events: none;
}
.notification-container--bottom-full,
.notification-container--top-full {
  width: 100%;
  min-width: 100%;
}
.notification-container--bottom-full {
  bottom: 0;
}
.flex-center {
  min-width: 325px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
}
.notification-container--top-center {
  top: 20px;
}
.notification-container--bottom-center {
  bottom: 20px;
}
.notification-container--top-left {
  left: 20px;
  top: 20px;
}
.notification-container--top-right {
  right: 20px;
  top: 20px;
}
.notification-container--bottom-left {
  left: 20px;
  bottom: 20px;
}
.notification-container--bottom-right {
  bottom: 20px;
  right: 20px;
}
.notification-container--mobile-bottom,
.notification-container--mobile-top {
  pointer-events: all;
  position: absolute;
}
.notification-container--mobile-top {
  right: 20px;
  left: 20px;
  top: 20px;
}
.notification-container--mobile-bottom {
  right: 20px;
  left: 20px;
  bottom: 20px;
  margin-bottom: -15px;
}
.notification__item--default {
  background-color: #007bff;
  border-left: 8px solid #0562c7;
}
.notification__item--default .notification__timer {
  background-color: #007bff;
}
.notification__item--default .notification__timer-filler {
  background-color: #fff;
}
.notification__item--default .notification__close {
  background-color: #007bff;
}
.notification__item--success {
  background-color: #28a745;
  border-left: 8px solid #1f8838;
}
.notification__item--success .notification__timer {
  background-color: #28a745;
}
.notification__item--success .notification__timer-filler {
  background-color: #fff;
}
.notification__item--success .notification__close {
  background-color: #28a745;
}
.notification__item--danger {
  background-color: #dc3545;
  border-left: 8px solid #bd1120;
}
.notification__item--danger .notification__timer {
  background-color: #dc3545;
}
.notification__item--danger .notification__timer-filler {
  background-color: #fff;
}
.notification__item--danger .notification__close {
  background-color: #dc3545;
}
.notification__item--info {
  background-color: #17a2b8;
  border-left: 8px solid #138b9e;
}
.notification__item--info .notification__timer {
  background-color: #17a2b8;
}
.notification__item--info .notification__timer-filler {
  background-color: #fff;
}
.notification__item--info .notification__close {
  background-color: #17a2b8;
}
.notification__item--warning {
  background-color: #eab000;
  border-left: 8px solid #ce9c09;
}
.notification__item--warning .notification__timer {
  background-color: #eab000;
}
.notification__item--warning .notification__timer-filler {
  background-color: #fff;
}
.notification__item--warning .notification__close {
  background-color: #eab000;
}
.notification__item--awesome {
  background-color: #685dc3;
  border-left: 8px solid #4c3fb1;
}
.notification__item--awesome .notification__timer {
  background-color: #685dc3;
}
.notification__item--awesome .notification__timer-filler {
  background-color: #fff;
}
.notification__item--awesome .notification__close {
  background-color: #685dc3;
}
@keyframes timer {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}
.notifications-component {
  position: fixed;
  z-index: 9000;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.notification__item {
  display: flex;
  position: relative;
  border-radius: 3px;
  margin-bottom: 15px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.notification-container--bottom-full .notification__item,
.notification-container--top-full .notification__item {
  margin-bottom: 0;
  border-radius: 0;
}
.notification__timer {
  width: 100%;
  margin-top: 10px;
}
.notification__timer,
.notification__timer-filler {
  height: 3px;
  border-radius: 5px;
}
.notification__title {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.notification__message {
  color: #fff;
  max-width: calc(100% - 15px);
  font-size: 14px;
  line-height: 150%;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
.notification__content {
  padding: 8px 15px;
  display: inline-block;
  width: 100%;
}
.notification__close {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 10px;
}
.notification__close:after {
  content: '\D7';
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 12px;
  left: 50%;
  top: 50%;
}
.notification-container--mobile-bottom .notification,
.notification-container--mobile-bottom .notification__item,
.notification-container--mobile-top .notification,
.notification-container--mobile-top .notification__item {
  max-width: 100%;
  width: 100%;
}
.notification-container--bottom-right .notification,
.notification-container--top-right .notification {
  margin-left: auto;
}
.notification-container--bottom-left .notification,
.notification-container--top-left .notification {
  margin-right: auto;
}
.notification-container--mobile-bottom .notification,
.notification-container--mobile-top .notification {
  margin-left: auto;
  margin-right: auto;
}

.d-flex {
  display: flex !important;
}

.db-card {
  height: 100px;
  /* max-width: 360px; */
}

.icon-box {
  background: rgba(237, 240, 244, 0.5);
  border-radius: 6px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-right {
  position: absolute;
  right: 24px;
  top: 12px;
  text-align: right;
}

.card-num {
  font-size: 38px;
  margin-bottom: 0px;
  font-weight: 700;
}

.card-txt {
  color: #666666;
  font-size: 14px;
}

.d-block {
  display: block;
}

.dashboard-icon {
  font-size: 24px;
}

.ant-table-wrapper.customTable {
  width: 100%;
  overflow-x: auto;
  tr:nth-child(2n) td {
    background-color: #edf0f4;
  }
  thead tr th {
    font-style: normal;
    line-height: 22px;
    background-color: $primary-color;
    height: 40px;
    color: #222222;
  }

  .ant-table-measure-row td {
    padding: 0px !important;
  }

  th,
  td {
    padding: 12px !important;
    min-width: 100px;
  }

  .ant-table-column-sorters {
    display: inline-flex;
  }
  .ant-table {
    color: #222 !important;
    th.ant-table-cell {
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .ant-table-content {
    overflow-x: auto;
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.listTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.mini-shop-button {
  background-color: $primary !important;
  color: white !important;
  border-radius: 5px;
  margin-left: 5px;
}

.mini-shop-breadcrumb {
  color: $primary !important;
}

.custom-paging {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  .ant-select-selector {
    height: 32px !important;
    .ant-select-selection-item {
      line-height: 32px !important;
    }
  }
  .text {
    padding: 0rem 0rem 0rem 1rem;
    border-radius: 3px;
    background: #edf0f4;
    height: 26px;
    .text_sub {
      color: #666666;
      position: relative;
      top: -5px;
    }

    .select_option {
      position: relative;
      top: -4px;
    }

    .select-paging {
      top: -5px;
    }

    .ant-select-selector {
      height: 30px !important;
      border: none !important;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: none;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: none;
    }
  }

  .ant-pagination-item-active a {
    color: $primary-color;
  }
  .ant-pagination-item-active {
    border-color: $primary-color;
  }

  .ant-pagination-item-active:focus-visible a,
  .ant-pagination-item-active:hover a {
    color: $primary-color;
  }

  .ant-select-arrow {
    font-size: 10px;
    top: 20px;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
    background: #edf0f4;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
  .ant-pagination-options {
    display: none;
  }
}

@media (max-width: 576px) {
  .custom-paging {
    flex-direction: column;
  }
}

.activeStatusBox {
  background-color: rgba(15, 164, 74, 1);
}
.inActiveStatusBox {
  background-color: red;
}

.unconfirmedStatusBox {
  background-color: orange;
}

.boxStatus {
  color: white;
  border-radius: 3px;
  display: inline-block;
  padding: 0 8px 0 8px;
}

.required-mark-after {
  .ant-form-item-label > label {
    height: 40px;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
  .ant-select-selection-placeholder {
    font-size: 14px;
  }

  .ant-input.ant-input-lg {
    font-size: 14px;
  }
  .ant-select-selection-item {
    font-size: 14px;
    color: #222222;
  }
  textarea {
    padding-top: 6px;
  }
}

.btn-back {
  padding-left: 10px;
  height: 40px;
  width: 42px;
  background: #e7eaee;
  border-radius: 3px;
  text-align: center;
  vertical-align: middle;
  margin-right: 8px;
}

.width-100 {
  width: 100%;
}

.banner-content-style {
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
}

.login-banner-content-style {
  height: 960px;
  color: #fff;
  line-height: 160px;
  text-align: center;
}

.ant-btn-primary {
  border: none;
  font-weight: bold;
  font-size: 14px;
}

.ant-table-column-has-sorters:hover {
  background-color: $primary-color !important;
}

.itemsetting {
  .itemsetting-form-item {
    margin: 0 !important;
    .ant-select-selector {
      padding: 0;
    }
  }
  .ant-row {
    display: block;
    .ant-col {
      max-width: 100% !important;
    }
  }
}

.ant-row {
  .ant-form-item-label label {
    word-break: break-word;
    white-space: normal;
  }
}

.ant-form-item {
  margin: 0 0 15px;
}

.ant-input,
.ant-input-password,
.ant-select .ant-select-selector,
.ant-picker {
  border-radius: 10px !important;
  padding-top: 2px;
  padding-bottom: 2px;
  max-height: 28px;
}

.ant-input-disabled {
  border: none !important;
  color: rgba($color: black, $alpha: 0.6) !important;
  padding: 0 !important;
  font-style: italic;
  background-color: white;
}

.ant-input-affix-wrapper-disabled {
  border: none !important;
  padding: 0;
  background: white;
}

.image {
  padding-right: 10px;
}

.ant-form-item-label > label:after {
  content: ' ';
}

.ant-list-item:hover {
  cursor: pointer;
}

.shop-list {
  padding: 30px;
  width: 85%;
}

.justify-content-between {
  justify-content: space-between;
  .dashboard-date-selection {
    width: 4vw;
    .ant-select-arrow, .ant-select-clear {
      top: 15px;
    }
  }
}
