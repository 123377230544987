@import './variables.scss';

.ant-table-wrapper.customTable {
  width: 100%;
  overflow-x: auto;
  tr:nth-child(2n) td {
    background-color: #edf0f4;
  }

  .ant-table-column-sorters {
    display: inline-flex;
    .ant-table-column-title {
      margin-right: 10px;
    }
  }
  .ant-table {
    color: #222 !important;
  }
  .ant-table-content {
    overflow-x: auto;
  }
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.listTitle {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.filterSelectColumn {
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.filterButton {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.marginRow {
  margin-bottom: 15px;
}
.marginSelect {
  margin-right: 40px;
}
.padding-title-list {
  padding-top: 8px;
}
.text-black-222 {
  color: #222 !important;
}
.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
