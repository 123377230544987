.login-title {
  font-weight: bold;
}
.login-card .ant-card-body {
  background-color: white !important;
}
.login-label {
  color: #666666;
}
.error-span {
  color: red;
  font-size: small;
}

.right-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
}
.login-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.remember-password {
  display: flex;
  justify-content: space-between;
}
.back-link {
  color: #666666;
}
