@import './variables.scss';

.ant-layout-sider.ant-layout-sider-dark {
  background-color: $sidebar-background-color;
  min-height: 100vh;
  box-shadow: 0px 8px 8px rgba(102, 102, 102, 0.05);
}

.logo-collapsed {
  opacity: 0;
}

.group-logo {
  background-color: $sidebar-background-color;
  display: flex;
  padding-top: 32px;
  padding-left: 8px;
  align-items: center;
  justify-content: space-between;
}

.group-logo-collapsed {
  display: flex;
  padding-top: 32px;
  padding-left: 26px;
  align-items: center;
  justify-content: flex-end;
}

.react-ant-button.btn-collpase {
  height: 24px;
  width: 24px;
  background-color: #e7eaee !important;
  border-radius: 3px 0px 0px 3px;
  border: none;
  padding: 4px 12px;
  img {
    padding-bottom: 10px;
    margin-left: -4px;
  }
}

.btn-collpase-done {
  position: absolute !important;
  transition: all 0.2s;
  img {
    transform: rotate(180deg);
    padding-bottom: 0px !important;
    padding-top: 10px;
  }
}

.menu-box-large {
  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow:before {
      transform: rotate(45deg) translateX(-2.5px);
    }
    .ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(-2.5px);
    }
  }
}

.sidebar-container {
  z-index: 3;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: $sidebar-background-color;
  color: $sidebar-color;
  .ant-menu {
    background-color: $sidebar-background-color;
    color: $sidebar-color;
    a,
    .ant-menu-item-icon {
      color: $sidebar-color;
    }
    .ant-menu-item.box-active {
      background-color: $primary-color;
    }
    .ant-menu-item.ant-menu-item-active:hover {
      background-color: rgba($color: $primary-color, $alpha: 0.5);
      a {
        color: white !important;
      }
    }
  }
  .ant-menu-vertical,
  .ant-menu-inline {
    border-right: 0;
  }
  .sidebar-menu {
    height: 95%;
    .menu {
      height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .sidebar-action-button {
    height: 5%;
    .ant-btn-text {
      color: $sidebar-color;
    }
  }
  .user-info {
    display: flex;
    padding: 0.6vw 0.7vw;
    background-color: $sidebar-background-color;
    .avatar-container {
      margin-right: 0.5vw;
    }
    .full-name {
      font-weight: bold;
      word-break: break-all;
    }
    .user-mail {
      color: rgba($color: grey, $alpha: 0.5);
      word-break: break-all;
    }
  }
  .user-info:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

@media (max-width: 576px) {
  .sidebar-container {
    text-align: center;
  }
  .sidebar-menu aside .ant-layout-sider-children {
    .user-info, .menu, .user-info .full-name, .user-info .user-mail {
      margin: 0 auto;
      display: block;
      text-align-last: center;
    }
  }
}