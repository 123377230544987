.user-form {
  margin-bottom: -24px;

  .block-1 {
    padding-bottom: 24px;
    border: none;
    height: fit-content;
    overflow-y: auto;
  }
  .block-3 {
    border: none;
    padding-top: 24px;
  }
  .block-2 {
    background: #edf0f4 !important;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .ant-picker {
    width: 100%;
    height: 40px;
  }

  .required-mark-after {
    .ant-picker-input #deadline,
    #createdAt,
    #purchasedAt {
      font-size: 14px;
    }
  }
  .label-1 {
    color: #666666;
    font-size: 14px;
    line-height: 22px;
  }
  #group-button-submit {
    button[type='button']:not(:last-child) {
      color: #666666;
    }
    display: flex;
    > * {
      margin-right: 8px;
      margin-left: 8px;
    }
    .close-button {
      background-color: rgba(237, 240, 244, 1);
    }
  }
  @media (max-width: 576px) {
    #group-button-submit {
      flex-direction: column;
      button {
        margin: 5px 0;
      }
    }
  }
  .header-label .ant-form-item-label > label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}
.user-form ~ p.footer {
  display: none;
}

.transaction-table {
  height: 100%;
}

.shop-avatar-container {
  display: block;
  margin: 10px auto;
  .avatar {
    border: 1px solid rgba($color: #000000, $alpha: 0.2);
  }
  .avatar:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  .upload-container {
    width: 150px;
    height: 150px;
    .ant-upload-picture-card-wrapper {
      height: 100%;
      .ant-upload-list-picture-card-container,
      .ant-upload.ant-upload-select-picture-card,
      .ant-upload-list.ant-upload-list-picture-card {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ant-upload-list-item,
  .ant-upload-list-item-info,
  .ant-upload.ant-upload-select {
    border-radius: 50% !important;
  }
}
.full-name-container {
  display: block;
  margin: 10px auto;
  font-size: 24px;
}
@media (max-width: 576px) {
  .full-name-container {
    font-size: 16px;
  }
}
