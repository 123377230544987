.login-container {
  padding-top: 20vh;
}
.login-title {
  font-weight: bold;
}
.login-card {
  background: #ffffff;
  border: 1px solid #edf0f4;
  box-sizing: border-box;
  box-shadow: 0px 8px 8px rgba(102, 102, 102, 0.05);
  border-radius: 3px;
  padding: 0px 24px !important;
}
.login-label {
  color: #666666;
}
.error-span {
  color: red;
  font-size: small;
}

.login-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .ant-picker {
    width: 100%;
  }
}

.remember-password {
  display: flex;
  justify-content: space-between;
}

.btn-login {
  width: 100%;
}
