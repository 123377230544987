.ant-card-body {
  border: none;
  .ant-form-item-control-input {
    padding-top: 0.4vw;
  }
}
.footer {
  text-align: center;
  padding-top: 32px;
  color: #666666;
}
.ant-card.ant-card-bordered.body-content {
  height: fit-content;
}
.top-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .button-group {
    display: flex;
    height: 40px;
  }
}

.body-content .ant-card-body {
  background-color: #ffffff;
  padding: 15px 24px;
}

.top-bar {
  display: flex;
  padding-bottom: 32px;
  justify-content: space-between;
}

.react-ant-button.btn-avatar {
  height: 40px;
  width: 42px;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
}

.react-ant-button.btn-setting {
  height: 40px;
  width: 42px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 98px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
}

.react-ant-button.btn-bell {
  height: 40px;
  width: 42px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 16px;
  text-align: center;
  vertical-align: middle;
  padding: 0px;
}

.btn-filter {
  position: relative;
  height: 40px;
  width: 40px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  z-index: 0;
}

.react-ant-button.btn-select-header {
  height: 40px;
  width: 42px;
  background: #e7eaee;
  border-radius: 3px;
  margin-left: 8px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
}

.react-ant-button.create-button {
  margin-left: 8px;
  margin-right: 8px;
}
.ant-layout {
  background: #f4f5f5 !important;
  z-index: 2;
  position: relative;
}

@media (max-width: 576px) {
  .top-content {
    flex-direction: column;
    .button-group {
      flex-direction: column;
      button {
        margin: 5px 0;
      }
    }
    .btn-filter {
      display: none !important;
    }
  }
}
