@import './variables.scss';

.cancel-button-custom {
  background-color: $graybg !important;
  border-radius: 3px !important;
  border: none !important;
  color: $lightGray !important;
}
.cancel-button-custom:focus {
  color: $lightGray !important;
  background-color: $graybg !important;
}
.update-button-custom {
  color: $white !important;
  background: $primary !important;
}
