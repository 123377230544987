.inventories-check-form {
  margin-bottom: -24px;

  .block-1 {
    padding-bottom: 24px;
    border: none;
    height: fit-content;
    overflow-y: auto;
  }
  .block-3 {
    border: none;
    padding-top: 24px;
  }
  .block-2 {
    background: #edf0f4 !important;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .ant-picker {
    width: 100%;
    height: 40px;
  }

  .required-mark-after {
    .ant-picker-input #deadline,
    #createdAt,
    #purchasedAt {
      font-size: 14px;
    }
  }
  .label-1 {
    color: #666666;
    font-size: 14px;
    line-height: 22px;
  }
  #group-button-submit {
    button[type='button']:not(:last-child) {
      color: #666666;
    }
    display: flex;
    > * {
      margin-right: 8px;
      margin-left: 8px;
    }
    .close-button {
      background-color: rgba(237, 240, 244, 1);
    }
  }
  .header-label .ant-form-item-label > label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
}
.inventories-check-form ~ p.footer {
  display: none;
}
